import React from 'react';
export default ({el, className})=>{
  return (
    <li className={className + ""}>
      <div className="block border-b-2 border-gray-200 border-solid py-2 flex">
        <i className="fas fa-chevron-right mr-4 text-blue-600 mr-2 mt-1"></i>
        <div className="flex-1">
          <p>{el.Descripcion}</p>
          {/* <span className="p-1 bg-green-100 mt-2 inline-block rounded text-green-800">${el.Precio1}</span>   */}
        </div>
        
        
      </div>
    </li>
  )
}