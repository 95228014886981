import React from 'react';
import Layout from '../components/layout'
import { graphql, Link }  from 'gatsby';
import Container from '../components/Container';
import PageHeader from '../components/PageHeader';
import Study from '../components/Study';
import PhoneNumber from '../components/PhoneNumber';
import Footer from '../components/Footer';

import VisaLogoIcon from '../../svg/visa.svg';
import MastercardLogoIcon from '../../svg/mastercard.svg';


const phones = [
  '961 61 147 73',
  '961 61 468 20',
  '961 61 253 44'

];

export default (props)=>{
  console.log(props);
  const category = props.data.categoriesJson;

  const studies = props.data.allStudiesJson.nodes;

  return(
    <Layout>
      <PageHeader>
        <h2 className="text-6xl">{category.name}</h2>
        <p className="mt-2 text-sm">
          <Link to="/" className="underline">Inicio</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="/servicios" className="underline">Servicios</Link>
          <i className="fas fa-chevron-right mx-2"></i>
          <Link to="#" >{category.name}</Link>
        </p>
          {/* <p className="my-4 max-w-xl mx-auto opacity-75 text-sm">{category.description}</p> */}
      </PageHeader>
      <Container>
        <div className="sm:flex-1 w-full px-4 sm:px-0">
          <header className="mt-8">
            {/* <p className="font-bold text-xl uppercase d-text-green ">Estudios disponibles</p> */}
            <p className="my-4 text-lg font-bold">{category.description}</p>
          </header>
          <div className="mt-8">
            <p>
              Consulta los precios en nuestra <a href="https://web.facebook.com/diagnosur/" rel="noopener noreferrer" target="_blank" className="d-text-green underline font-bold">página de Facebook</a> o llamando a uno de nuestros teléfonos:
            </p>
            <ul className="text-2xl flex flex-wrap justify-center text-center list-none">
              {
                phones.map((phoneNumber, index) => (
                  <li className="w-1/2 sm:w-1/3 p-4" key={index}>
                    <PhoneNumber number={phoneNumber} />
                  </li>
                ))
              }
            </ul>
          </div>
          <ul className="studies flex flex-wrap mt-6 mb-8 border-t-2 border-gray-200 pt-4">
            {
              studies.map((el,index) => (
                <Study el={el} className="list-none w-full sm:w-1/2 flex-shrink-0 p-2 mt-2" key={index}></Study>
              ))
            }
          </ul>
          
          <div className="mt-8 text-center">
            <p className="text-2xl font-bold">
              Aceptamos tarjetas de crédito y débito
            </p>
            <div className="flex my-4 justify-center">
              <MastercardLogoIcon style={{ height: "60px" }} className="mr-4" />
              <VisaLogoIcon style={{height:"60px"}} />
            </div>
            
          </div>
        </div>
      </Container>
      <Footer />
    </Layout>
  );
}

export const query = graphql`
  query($categoria : Int, $slug: String){

    allStudiesJson(filter: {Categoria: {eq: $categoria}}) {
      nodes {
        Descripcion
        Indicaciones
        Precio1
      }
    }

    categoriesJson(slug:{eq:$slug}){
      name
      description
    }
  }
`;